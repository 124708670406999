import { _ } from "$json/lib/gettext";

_.set({
  "lang": "en",
  "dateplaceholder": "mm/dd/yyyy",
  "field--value": "this value",
  "field--date": "this date",
  "field--field": "this field",
  "field--list": "these options",
  "quoted--value": "the value “{quotable}”",
  "quoted--date": "the date “{quotable}”",
  "quoted--field": "the field “{quotable}”",
  "quoted--list": "the options in the list “{quotable}”",
  "Input required": "{quotable--init} is required",
  "Choice required": "Choose one of the options under {quotable--lower}",
  "Field required": "{quotable--init} is required",
  "cWebIllegalAccess": "You are not allowed to open or start this case.",
  "Date required":
    "You haven't yet filled in {quotable--lower}; use the format mm/dd/yyyy",
  "Value has to lie between {minimum} and {maximum}":
    "{quotable--init} has to lie between {minimum} and {maximum}; use the format mm/dd/yyyy",
  "Date has to lie between {minimum} and {maximum}":
    "{quotable--init} has to lie between {minimum} and {maximum}; use the format mm/dd/yyyy",
  "A date after {maximum} is not allowed":
    "{quotable--init} after {maximum} is not possible; use the format mm/dd/yyyy",
  "A date before {minimum} is not allowed":
    "{quotable--init} before {minimum} is not possible; use the format mm/dd/yyyy",
  "Invalid date": "{quotable--init} is invalid; use the format mm/dd/yyyy",
  "Negative number or zero expected":
    "{quotable--init} should be a negative number or zero",
  "Negative number expected": "{quotable--init} should be a negative number",
  "Value has to lie below {maximum}":
    "{quotable--init} has to lie below {maximum+1}",
  "Positive number or zero expected":
    "{quotable--init} has to be a positive number or zero",
  "Positive number expected": "{quotable--init} has to be a positive number",
  "Value has to lie above {minimum}":
    "{quotable--init} has to lie above {minimum-1}"
});
