import { bb, _ } from "$json";
import { fromApiServer } from "$json/lib/location";
/* tabs:
 *
 * Show or hide tabs depending
 * on the model author info.
 * Also set the iFrame src.
 *
 * Author: Tim Bauwens
 * Copyright 2017 Berkeley Bridge
 *
 */

(function ($, doc, win) {
  // Allow states to be set in conf per layer:
  let defaultStates = {
    stws: 2,
    snws: 3,
    stss: 5,
    snss: 6
  };
  let confStates = bb.propFinder(bb.conf, "template")("toggle-states", {});
  let states = Object.assign(defaultStates, confStates);
  const STATE_TABS_WITH_STEPS = states.stws;
  const STATE_NOTABS_WITH_STEPS = states.snws;
  const STATE_TABS_SANS_STEPS = states.stss;
  const STATE_NOTABS_SANS_STEPS = states.snss;

  $(function () {
    var $article = $("#bb-p-tab-content-audit div"); // div for document container on this page
    var _textURL; // which document?
    var _outside = false;
    var _snips = [];

    function reload() {
      bb.Mode.set("hasText");
      bb.Mode.set("hasTextInside");

      if (!_outside) {
        bb.Mode.set("hasTextInside");
        _textURL =
          "report?dbname=" +
          bb.getVar("dbname") +
          "&sessionid=" +
          bb.getVar("sessionid") +
          "&uniqueid=" +
          bb.getVar("uniqueid") +
          "&template=report" +
          "&_nocache=" +
          new Date().getTime() +
          " .bb-path";
        $article.load(fromApiServer(_textURL), function () {
          var $btn = $(
            "<button " +
              'class="bb-p-preview-outside" ' +
              'title="' +
              _("open this preview in a new window") +
              '">' +
              "<svg " +
              'height="16px" ' +
              'width="16px" ' +
              'xmlns="http://www.w3.org/2000/svg" ' +
              'viewBox="0 0 1000 1000">' +
              "<path " +
              'd="M149.4,966.1l677.2-677.2v292.8c0,45.1,36.6,81.7,81.7,81.7c45.1,0,81.7-36.6,81.7-81.7v-490c0-33-19.9-62.8-50.4-75.4c-10.1-4.2-20.7-6.2-31.3-6.2v0h-490c-45.1,0-81.7,36.6-81.7,81.7c0,45.1,36.6,81.7,81.7,81.7h292.8L33.9,850.6C18,866.5,10,887.4,10,908.3s8,41.8,23.9,57.7C65.8,998,117.5,998,149.4,966.1L149.4,966.1z" ' +
              'fill="currentColor"/>' +
              "</svg>" +
              "</button>"
          );
          $btn.on("click", function () {
            loadOutside(_textURL);
          });

          $article.prepend($btn);
          var $snippets = $article.find("h1,h2,h3,h4,h5,h6,p,ul");
          var tmpsnips = [];
          let num = 0;
          $.each($snippets, function () {
            var $this = $(this),
              snip = $this.html();
            if ($.inArray(snip, _snips) == -1) {
              $this.addClass("bb-p-preview-new");
              if (++num == 1) $article.scrollTo(this);
            }
            tmpsnips.push(snip);
          });
          _snips = tmpsnips;
        });
      }
      if (_outside) {
        loadOutside();
      }
    }

    function loadOutside() {
      _outside = true;
      bb.Mode.unset("hasTextInside");
      $article.empty();
      _textURL =
        "report?dbname=" +
        bb.getVar("dbname") +
        "&sessionid=" +
        bb.getVar("sessionid") +
        "&uniqueid=" +
        bb.getVar("uniqueid") +
        "&template=report" +
        "&_nocache=" +
        new Date().getTime();

      var w = window.open(
        fromApiServer(_textURL),
        "Preview",
        "scrollbars=yes,resizable=yes",
        true
      );
      if (!w.closed)
        var watchClose = win.setInterval(function () {
          try {
            if (w.closed) {
              win.clearTimeout(watchClose);
              //Do something here...
              _outside = false;
              bb.Mode.set("hasTextInside");
              reload();
            }
          } catch (e) {
            return;
          }
        }, 200);
    }

    // add reload function to each navigatory action:
    $(doc).on("bb:postHandleData", function (event, data) {
      // The test /could/ of course look directly inside
      // data.groups[last].controls, but looking at the DOM
      // means less code for me. This does mean bb:preHandleData
      // would not work.
      if (data && data.groups && data.groups.length > 0) {
        reload();
      }
      if (
        (data && data.status && data.status === "logout successful") ||
        (data.models && data.models.length > 0)
      ) {
        bb.Mode.unset("hasText");
      }
    });
  });

  var tabbing = false,
    layout = {
      current: {
        state: 0,
        busy: false,
        session: 0
      },
      reposition: function (state) {
        var $button = $(".bb-p-tabs-state-toggle"),
          $nav = $("nav.bb-p-tabs-nav"),
          $content = $(".bb-p-tabs-content"),
          columns = {
            $left: $("#bbq-column"),
            $mid: $("#jumplist-column"),
            $right: $("#tabs-column")
          };
        if (isNaN(state) || state === 0 || state === this.current.state) {
          return false;
        } else if (state === 1) {
          columns.$left.attr("class", "column column--5");
          columns.$mid.attr("class", "column column--2");
          columns.$right.attr("class", "column column--5");
          $content.fadeIn(200);
          $button.attr({
            "aria-pressed": "false"
          });
        } else if (state === STATE_TABS_WITH_STEPS) {
          columns.$left.attr("class", "column column--7");
          columns.$mid.attr("class", "column column--2");
          columns.$right.attr("class", "column column--0");
          $nav
            .find('[role="tab"]')
            .attr("aria-selected", false)
            .toggleClass("showing", false);
          $content.fadeOut(200);
          $button.attr({
            "aria-pressed": "true"
          });
        } else if (state === STATE_NOTABS_WITH_STEPS) {
          columns.$left.attr("class", "column column--7");
          columns.$mid.attr("class", "column column--2");
          columns.$right.attr("class", "column column--0");
          $content.fadeOut(200);
          $button.attr({
            "aria-pressed": "true"
          });
        } else if (state === 4) {
          columns.$left.attr("class", "column column--7");
          columns.$mid.attr("class", "column column--0");
          columns.$right.attr("class", "column column--5");
          $content.fadeIn(200);
          $button.attr({
            "aria-pressed": "false"
          });
        } else if (state === STATE_TABS_SANS_STEPS) {
          columns.$left.attr("class", "column column--7");
          columns.$mid.attr("class", "column column--0");
          columns.$right.attr("class", "column column--0");
          $nav
            .find('[role="tab"]')
            .attr("aria-selected", false)
            .toggleClass("showing", false);
          $content.fadeOut(200);
          $button.attr({
            "aria-pressed": "true"
          });
        } else if (state === STATE_NOTABS_SANS_STEPS) {
          columns.$left.attr("class", "column column--7");
          columns.$mid.attr("class", "column column--0");
          columns.$right.attr("class", "column column--0");
          $content.fadeOut(200);
          $button.attr({
            "aria-pressed": "true"
          });
        }
        this.current.state = state;
      },
      request: function (request, postpone) {
        var desired;
        postpone = postpone !== "undefined" ? postpone : false;
        if (this.current.busy) {
          if (!postpone) {
            setTimeout(function () {
              layout.request(request, true);
            }, 100);
          }
          return false;
        } else if (request === "reset") {
          this.current.busy = true;
          desired = bb.Mode.get("hasTabs")
            ? bb.Mode.get("hasJumplist") || bb.Mode.get("hasChapters")
              ? STATE_TABS_WITH_STEPS
              : STATE_TABS_SANS_STEPS
            : bb.Mode.get("hasJumplist") || bb.Mode.get("hasChapters")
            ? STATE_NOTABS_WITH_STEPS
            : STATE_NOTABS_SANS_STEPS;
        } else if (request === "postChk") {
          this.current.busy = true;
          // In case there was no jumplist, is there one now?
          if (
            /[4,5,6]/.test(this.current.state) &&
            (bb.Mode.get("hasJumplist") || bb.Mode.get("hasChapters"))
          ) {
            // Minus 3 always results in the appropriate state plus jumplist.
            desired = this.current.state - 3;
          } else {
            // Nothing to do.
            desired = this.current.state;
          }
        } else if (request === "hide-tabs") {
          if (/[1,4]/.test(this.current.state)) {
            desired = this.current.state + 1;
          } else {
            desired = this.current.state;
          }
        } else if (request === "show-tabs") {
          if (/[2,5]/.test(this.current.state)) {
            desired = this.current.state - 1;
          } else {
            desired = this.current.state;
          }
        }
        this.reposition(desired);
        setTimeout(function () {
          $('[data-if="ui-ready"]').animate({ opacity: 1 }, 300);
        }, 150);
        setTimeout(function () {
          layout.current.busy = false;
        }, 150);
      }
    };

  function srcToggle(requestedSRC) {
    var currentSRC = $("#bb-p-tabs-iframe").attr("src"),
      defaultSRC = $("#bb-p-tabs-iframe").data("defaultSRC");
    if (requestedSRC === "default") {
      if (defaultSRC == "(unknown)" || defaultSRC == undefined) {
        $("#bb-p-tabs-iframe").attr("src", "");
        bb.Mode.unset("hasIframe");
        var currentTab = $("nav.bb-p-tabs-nav button.showing").data("tab");
        if (currentTab === "bb-p-tab-content-iframe") {
          layout.request("hide-tabs"); // iFrame is gone, hide the tab.
        }
        return false;
      }
      requestedSRC = defaultSRC;
    }
    if (requestedSRC === currentSRC) {
      return false;
    } else {
      bb.Mode.set("hasIframe");
      $("#bb-p-tabs-iframe").attr("src", requestedSRC);
    }
  }

  function tabToggle($tab) {
    if (tabbing) {
      return false;
    } else {
      tabbing = true;
      var currentTab = $("nav.bb-p-tabs-nav button.showing").data("tab");
      var requestedTab = $tab.data("tab");
      if (currentTab === requestedTab) {
        tabbing = false;
        layout.request("hide-tabs");
      } else {
        $('nav.bb-p-tabs-nav button[role="tab"]')
          .removeClass("showing")
          .attr("aria-selected", "false");
        $tab.addClass("showing").attr("aria-selected", "true");
        var $tabContent = $("#" + requestedTab);
        $(".bb-p-tabs-tab").toggle(false);
        layout.request("show-tabs");
        $tabContent.slideToggle(75, function () {
          tabbing = false;
        });
        setTimeout(function () {
          $tabContent[0].scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }, 150);
      }
    }
  }

  $(doc).ready(function () {
    $("button.bb-p-tabs-state-toggle").on("click", function () {
      layout.request("hide-tabs");
    });
  });

  $(doc).on("bb-p:modelinfo", function (e, model) {
    if (model && model.model) {
      if (
        model.model["tab-state"] === "undefined" ||
        model.model["tab-state"] !== "off"
      ) {
        bb.Mode.set("hasTabs");
        bb.Mode.unset("hasIframe");
        $('nav.bb-p-tabs-nav button[role="tab"]')
          .off("click.tabs")
          .each(function (i, v) {
            $(v).on("click.tabs", function () {
              tabToggle($(v));
            });
          });
        $.each(model.model, function (k, v) {
          if (k === "tab-name-iframe") {
            $(
              '[data-tab="bb-p-tab-content-iframe"] span.p-tabs-dynamic-name'
            ).html(v);
          } else if (k === "tab-name-previews") {
            $(
              '[data-tab="bb-p-tab-content-previews"] span.p-tabs-dynamic-name'
            ).html(v);
          } else if (k === "tab-name-audit") {
            $(
              '[data-tab="bb-p-tab-content-audit"] span.p-tabs-dynamic-name'
            ).html(v);
          }
        });
        if (model.model["tab-iframe"]) {
          let defaultSRC =
            model.model["tab-iframe"].substr(-3) === "pdf"
              ? model.model["tab-iframe"] + "#view=FitH"
              : model.model["tab-iframe"];
          $("#bb-p-tabs-iframe").data("defaultSRC", defaultSRC);
          bb.Mode.set("hasIframe");
        } else {
          $("#bb-p-tabs-iframe").removeData("defaultSRC");
        }
      } else {
        bb.Mode.unset("hasTabs");
      }
      layout.request("reset");
    }
  });

  $(doc).on("bb:preHandleData", function (e, data) {
    if (data.models) {
      // Hide the columns altogether. They will be shown when ready.
      $('[data-if="ui-ready"]').animate({ opacity: 0 }, 0);
    }
    if (data.informationsources) {
      var requestedSRC = "default",
        flag = false;
      $.each(data.informationsources, function (index, src) {
        if (src.isurl && !src.external) {
          requestedSRC =
            src.content.substr(-3) === "pdf"
              ? src.content + "#view=FitH"
              : src.content;
          flag = index;
        }
      });
      (flag || flag === 0) && data.informationsources.splice(flag, 1);
      setTimeout(function () {
        srcToggle(requestedSRC);
      }, 100);

      if (!flag) {
        /*
          There's no node specific external resource,
          but make sure the resource tab is displaying the right src.
        */
        srcToggle("default");
      }
    }
  });

  $(doc).on("bb:postHandleData", function (event, data) {
    if (data && data.groups && !data.models) {
      if (layout.current.session !== data.sessionid) {
        setTimeout(function () {
          layout.request("reset");
          layout.current.session = data.sessionid;
        }, 1000);
      } else {
        setTimeout(function () {
          layout.request("postChk");
          // If the audit trail is open, scroll to group.
          let tabs = doc.querySelector("section.bb-p-tabs-content");
          let audit = doc.getElementById("bb-p-tab-content-audit");
          if (
            tabs.style.display === "block" &&
            audit.style.display === "block"
          ) {
            location.hash = data.groups[data.groups.length - 1].name;
          } else {
            location.hash = "";
          }
        }, 200);
      }
    }
  });

  // A handler to move the toggles for better mobile ux.
  function resizeCheck() {
    // Check the current window size, and move the nav if necessary
    var winW = win.innerWidth,
      breakpoint = bb.conf.template.breakpoint
        ? bb.conf.template.breakpoint
        : 769,
      currentpos = $("#bb-p-tabs-nav-container").attr("data-position");

    if (winW < breakpoint) {
      if (currentpos === "desktop") {
        $("#bb-p-tabs-nav-container")
          .insertBefore($("#tabs-column"))
          .attr("data-position", "mobile");
      }
    } else {
      if (currentpos === "mobile") {
        $("#bb-p-tabs-nav-container")
          .insertBefore($('main[role="main"]'))
          .attr("data-position", "desktop");
      }
    }
  }

  $(win).on("resize", resizeCheck);

  setTimeout(resizeCheck, 500); // To force once.

  /*
    A mutation observer to allow other plugins
    to also hook into the external resources
    tab.
  */
  $(doc).ready(function () {
    var iframe = document.getElementById("bb-p-tabs-iframe"),
      config = { attributes: true },
      hook = function (mutationsList, observer) {
        $.each(mutationsList, function (i, mutation) {
          if (mutation.attributeName === "data-hooked") {
            bb.Mode.set("hasIframe");
            var $toggler = $("#bb-p-tab-toggle-iframe");
            if (!$toggler.hasClass("showing")) {
              $toggler.click();
            }
          }
        });
      },
      observer = new MutationObserver(hook);

    observer.observe(iframe, config);
  });
})(jQuery, document, window);
