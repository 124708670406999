/* modelinfo:
 *
 * Get info for current model
 *
 * @dependencies: mustache
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
import Mustache from "$mustache";
import { fromApiServer } from "$json/lib/location.js";
import { has } from "$json/lib/functional.js";
(function ($, win, doc) {
  var _info = [],
    infoNodes,
    hash = {},
    lastId;

  $(function () {
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && has("modelid", data))
        if (!_info[data.modelid]) {
          $.get(
            fromApiServer(
              "api/models/" + data.modelid + "?uniqueid=" + data.uniqueid
            )
          ).then(function (res) {
            var model = new Model(res);
            _info[data.modelid] = model;
            model.replace();
          });
        } else {
          _info[data.modelid].replace();
        }
    });

    Mustache.tags = ["[[", "]]"];
    // templates = $('.bb-p-modelinfo-template');
    infoNodes = document.querySelectorAll(".p-modelinfo");

    // if (!(templates && infoNodes))
    //   return false;

    function Model(res) {
      for (var prop in res) {
        if (has(prop, res)) this[prop] = res[prop];
      }
      this.author = {
        name: res.authorname,
        info: res.authorinfo
      };
      this.model = {
        info: res.extramodelinfo
      };
      this.setInfo("author");
      this.setInfo("model");
      return this;
    }

    Model.prototype.setInfo = function (prop) {
      var info = String(this[prop].info).split(/\n/);
      info.map(
        function (line) {
          var kv = line.split(/\:/),
            k = kv[0],
            v = kv.slice(1).join(":");
          this[prop][k] = v;
        }.bind(this)
      );
      delete this[prop].info;
    };

    Model.prototype.replace = function () {
      if (lastId !== this.id) $(doc).trigger("bb-p:modelinfo", this);
      lastId = this.id;
      // The following could be conditional upon lastId !== this.id too, probably.
      for (var i = 0; i < infoNodes.length; ++i) {
        var infoNode = infoNodes[i];
        if (infoNode.getAttribute("data-p-modelinfo-id") === String(this.id)) {
          // no need to replace
        } else {
          var tmpl = $(
            "#" + infoNode.getAttribute("data-p-modelinfo-template")
          ).html();
          var contents = Mustache.render(tmpl, this);
          infoNode.innerHTML = contents;
          infoNode.setAttribute("data-p-modelinfo-id", this.id);
        }
      }
    };

    return true;
  });
})(jQuery, window, document);
