/* max-chars-indicator
 *
 * Warn users when a field is approaching the max chars.
 *
 * Author: Tim Bauwens
 * Copyright 2020 Berkeley Bridge
 *
 */

import { conf } from "$json/lib/conf.js";
import { curry, pathOr } from "$json/lib/functional.js";
import { occurrences } from "$json/lib/text-utils.js";

const numofnewlines = curry(occurrences)("\n");

const check = e => {
  if (!e.target.hasAttribute("maxlength")) return;
  if (
    !e.target.matches(
      pathOr("*", ["arbitrary", "max-chars-indicator", "selector"], conf)
    )
  )
    return;
  try {
    const max = e.target.getAttribute("maxlength"),
      threshold = Math.floor(0.5 * max),
      current = e.target.value.length + numofnewlines(e.target.value),
      diff = max - current,
      zone = Math.min(Math.floor((current / max) * 10) - 5, 6),
      id = e.target.id;

    if (diff <= threshold) {
      const warning =
        document.querySelector(
          `.p-max-chars-indicator[data-warning-for="${id}"]`
        ) || document.createElement("span");
      warning.className = "p-max-chars-indicator";
      warning.setAttribute("data-warning-for", id);
      warning.setAttribute("data-danger-zone", zone);
      warning.innerText = `${current} / ${max}`;
      if (e.target.closest(".xarea")) {
        e.target.closest(".xarea").insertAdjacentElement("afterend", warning);
      } else e.target.insertAdjacentElement("afterend", warning);
    } else {
      const warning = document.querySelector(
        `.p-max-chars-indicator[data-warning-for="${id}"]`
      );
      if (warning) warning.remove();
    }
  } catch (err) {
    window.console && window.console.warn(err);
  }
};

document.addEventListener("input", check, true);
