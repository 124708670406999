/* case-filter-extra:
 *
 * Add the clear input x
 *
 * Author: Tim Bauwens
 * Copyright 2017 Berkeley Bridge
 *
 */
(function ($, doc) {
  var $filter;
  var $clear;

  $(function () {
    $filter = $("#bb-cases-filter");
    $clear = $("#bb-p-case-filter-clear");
    $clear.on("click touchstart", function () {
      $filter.val("").focus();
    });
  });
})(jQuery, document);
