import { escapeHTML } from "$json/lib/escape";
import { positionalFormat } from "$json/lib/text-utils";
import { _ } from "$json/lib/gettext";
import { bb } from "$json";

bb.createModelItem = function (model) {
  return (
    '<div data-bb:dbname="' +
    model.dbname +
    '"' +
    ' class="bb-model ' +
    model._selectedclass +
    " bb-model-yours-" +
    model.yours +
    '">' +
    '<div class="bb-model-name">' +
    escapeHTML(model._nicename) +
    "</div>" +
    '<button class="bb-newcase" tabindex="0" aria-label="' +
    escapeHTML(positionalFormat(_("start model {0}"), model._nicename)) +
    '">' +
    _("start") +
    "</button>" +
    (model.caselistingallowed && model.casecount
      ? '<button class="bb-model-cases" tabindex="0">' +
        _("saved sessions") +
        " (" +
        model.casecount +
        ")" +
        "</button>"
      : "") +
    "</div>"
  );
};
