/* session-timeout-indicator
 *
 * Warn users when session is about to timeout
 *
 * Author: Tim Bauwens
 * Copyright 2020 Berkeley Bridge
 *
 */

import { bb } from "$json";
import Dialogue from "$Dialogue";

((doc, $) => {
  var lang = bb.conf.lang || "en",
    timer,
    countdown,
    warningTime = 120, // Seconds of warning
    remaining = warningTime, // Initially
    originalTitle, // Stored just before change.
    dialogue = new Dialogue();

  const DICT = {
    "en": {
      warning: "Warning",
      message: "Your session is about to expire",
      renew: "Renew session?"
    },
    "en-GB": {
      warning: "Warning",
      message: "Your session is about to expire",
      renew: "Renew session?"
    },
    "nl": {
      warning: "Warning",
      message: "Your session is about to expire",
      renew: "Renew session?"
    }
  };

  let $warningTemplate = $(`
    <div class="p-session-timeout-indicator">
      <strong>${DICT[lang].warning}:</strong><br />
      <p>
        ${DICT[lang].message}
      </p>
      <p class='countdown'>${remaining}s remaining.</p>
    </div>
  `);

  let $renewer = $(`
    <button>${DICT[lang].renew}</button>
  `).on("click", e => {
    bb.update();
    dialogue.destroy();
  });

  $warningTemplate.append($renewer);

  let counter = () => {
    if (remaining > 15) {
      if (document.querySelector(".countdown"))
        document.querySelector(".countdown").innerText =
          `${remaining}s remaining.`;
      document.getElementsByTagName("title")[0].innerText =
        `${remaining}s remain`;
    } else if (remaining > 0) {
      // Less than 15s remain, get persistant.
      if (!document.querySelector(".countdown")) {
        dialogue.render($warningTemplate.get(0));
      }
      document.querySelector(".countdown").innerText =
        `${remaining}s remaining.`;
      $warningTemplate.toggleClass("annoying", true);
      document.getElementsByTagName("title")[0].innerText =
        `WARNING: ${remaining} seconds remain`;
    } else {
      destroyCounter();
      bb.logout();
    }
    remaining--;
  };

  let destroyCounter = () => {
    window.clearInterval(countdown);
    dialogue.destroy();
    remaining = warningTime;
    document.getElementsByTagName("title")[0].innerText = originalTitle;
    $warningTemplate.toggleClass("annoying", false);
    $warningTemplate.find(".countdown").text(warningTime + "s remaining.");
  };

  $(doc).on("bb:postHandleData", (e, data) => {
    if (data.ttl) {
      window.clearTimeout(timer);
      window.clearTimeout(countdown);
      originalTitle = document.getElementsByTagName("title")[0].innerText;
      destroyCounter();
      timer = window.setTimeout(
        () => {
          originalTitle = document.getElementsByTagName("title")[0].innerText; // Yes, it's necessary to have this statement twice. (Race cond. with history.js)
          dialogue.render($warningTemplate.get(0));
          countdown = window.setInterval(counter, 1000);
        },
        (data.ttl - warningTime - 15) * 1000
      ); // Minus 15 as buffer - avoid the server timing out before us.
    }
  });
})(document, jQuery);
