/* wrap-table
 *
 * Wrap tables in separate div to be able to scroll horizontally
 *
 * Author: Niels Giesen
 * Copyright 2015, 2016 Berkeley Bridge
 *
 */

import { bb } from "$json";

let $ = jQuery;

const config = bb.propFinder(bb.conf, "arbitrary.wrap-table");
const wrapToggler =
  bb.conf.plugins.includes("grids-to-cards-toggle") &&
  config("wrap-toggler") === true;

function wrapHandler(event, data) {
  if (data && data.groups)
    $(".bb-questionlabelgroup>table, .group>table").each(function (_, table) {
      let wrap = document.createElement("div");
      let toggler = wrapToggler && table.previousElementSibling;
      wrap.setAttribute("class", "bb-p-wrap-table");
      table.parentNode.replaceChild(wrap, table);
      wrap.appendChild(table);
      if (toggler && toggler.classList.contains("bb-p-grid-to-card-toggle"))
        wrap.insertAdjacentElement("afterBegin", toggler);
    });
}

$(function () {
  $(document).on("bb:postHandleData", wrapHandler);
});
