/* case-template:
 *
 * Use a template to render a case element
 *
 * Author: Niels Giesen
 * Copyright 2014 Berkeley Bridge
 *
 */

import { Mustache } from "$mustache";
import { bb } from "$json";

(function ($, win, doc) {
  var tmpl;

  bb.createCaseItem = function (cas) {
    return Mustache.render(tmpl, cas);
  };

  $(function () {
    tmpl = $("#bb-p-case-template").html();
    Mustache.tags = ["[[", "]]"];
  });
})(jQuery, window, document);
