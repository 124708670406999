import { bb } from "$json";
/* headings:
 *
 * Turn labels whose font class (as provided the JSON object) match the
 * regular expression /^Standard title$|^Heading[^1-6]*([1-6]?)/ into headings.
 *
 * If the class has no numbering (e.g. 'Standard Title' instead of
 * 'Heading 2'), level 1 will be presumed.
 *
 * Since a model can run anywhere, it is possible to set a base level
 * for headings in arbitrary.headings.baseLevel (in
 * conf.json).
 *
 * If you want to force titles in 'addtobottom' nodes to duck below
 * the top ('cleared') node, declare arbitrary.headings.duck = true in
 * conf.json
 *
 * Author: Niels Giesen
 * Copyright 2016, 2018 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  var duck = false,
    baseLevel,
    topLevel,
    re = /^Standard title$|^Heading[^1-6]*([1-6]?)/;

  $(function () {
    try {
      baseLevel = bb.conf.arbitrary.headings.baseLevel;
      if (baseLevel === undefined) throw "baseLevel is not defined";
      topLevel = baseLevel - 1;
    } catch (err) {
      topLevel = 0;
    }

    try {
      duck = bb.conf.arbitrary.headings.duck;
    } catch (err) {}

    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.groups && data.groups.length) {
        $.each(data.groups, function (_, group) {
          $.each(group.controls, function (_, ctl) {
            var klass = ctl["font-class"],
              _level,
              m;
            if (ctl.controltype === "label" && ((m = klass.match(re)), m)) {
              _level = Number(m[1]) || 1;
              ctl._subtype = "heading";
              ctl._level =
                duck && group.screenmode !== "clear"
                  ? _level + 1 + topLevel
                  : _level + topLevel;
            }
          });
        });
      }
    });
  });
})(jQuery, window, document);
