/* global jQuery */
import { conf } from "$json/lib/conf.js";
/*
 * reset-password:
 *
 * This global plugin gives the theme
 * a reset link below the login form
 * which will link to the live/ html
 * - a theme ambiguous simple layer
 * handling all aspects of resulting
 * password changing methods.
 *
 * NB: Dependencies:
 *
 * There needs to be a plugin-specific
 * link hard-coded into the theme, e.g.:
 *

 {{#plugins.reset-password}}
 <tr>
   <td colspan="2">
     <a
       id="p-reset-password-link"
       href="plugins/reset-password/live/newpwd.html">
         I forgot my password...
     </a>
   </td>
 </tr>
 {{/plugins.reset-password}}

 * Author: Tim Bauwens
 * Copyright 2018 Berkeley Bridge
 *
 */
(function ($) {
  $(document).on("click", "#p-reset-password-link", event => {
    try {
      const lang = conf.lang;
      const helpdesk =
        conf.arbitrary && conf.arbitrary.resetpasswordHelpdesk != undefined
          ? conf.arbitrary.resetpasswordHelpdesk
          : "";
      const theme =
        self === top ? window.location.href : "iframe+" + document.referrer;
      const url = new URL(location);
      url.pathname += "plugins/reset-password/live/newpwd.html";
      const search = new URLSearchParams({ theme, lang, helpdesk });
      url.search = search;
      event.preventDefault();
      location = url.toString();
    } catch (e) {
      /*
        If we couldn't determine the theme or lang,
        it's not worth sending the user
        potentially back to the wrong place.
      */
      $("#p-reset-password-link").hide();
    }
  });
})(jQuery);
