/* array.groupby:
 *
 * Extend Array prototype with _groupby(function) method.
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
Array.prototype._groupby = function (hash) {
  var _hash = hash
    ? hash
    : function (o) {
        return o;
      };

  var _map = {};
  var put = function (map, key, value) {
    if (!map[_hash(key)]) {
      map[_hash(key)] = [];
    }
    map[_hash(key)].push(value);
  };

  this.map(function (obj) {
    put(_map, obj, obj);
  });

  var out = [];
  for (var key in _map) {
    out.push(_map[key]);
  }
  return out;
};
