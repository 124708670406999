/* model-filter:
 *
 * Filter models by name
 *
 * Author: Niels Giesen
 * Copyright 2013, 2016 Berkeley Bridge
 *
 */
(function ($, doc) {
  var $filter;

  $(function () {
    var $doc = $(doc);
    $filter = $("#bb-p-model-filter");

    $doc
      .on("bb:postHandleData", filter)
      .on("keyup", "#bb-p-model-filter", filter)
      .on("click touchstart", "#bb-p-model-filter-clear", clear);

    $filter.on("keydown", function (ev) {
      if (ev.keyCode === 13) {
        var $filtered = $(".bb-model:visible");
        if ($filtered.length === 1)
          $filtered.find(".bb-newcase").addBack().last().trigger("click");
      }
    });
  });

  function filter() {
    $("#bb-p-model-filter-nomatches").hide();
    var val = $.trim($filter.val());
    var hasmatch = false;
    if (val.length >= 3) {
      var re = new RegExp(val, "i");
      $(".bb-model").each(function () {
        var $this = $(this);
        // Find the description in the child or -- when absent -- as
        // the text content itself
        var text = $this.find(".bb-model-name").text() || $this.text();
        var ismatch = val.length < 3 || re.test(text);
        hasmatch = hasmatch || ismatch;
        $this.toggle(ismatch);
      });
    }
    $filter.toggleClass("bb-p-model-filter-has-no-match", !hasmatch);
    if (!hasmatch) {
      $(".bb-model").show();
    }
  }

  function clear() {
    $filter.val("").trigger("keyup").trigger("focus");
  }
})(jQuery, document);
