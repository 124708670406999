import { compose, find, propEq, prop, map, filter, when } from "./functional";

export const collectAttributes = (prefix, elt) =>
  compose(
    Object.fromEntries,
    map(({ name, value }) => [name.substr(prefix.length), value]),
    filter(({ name, value }) => name.startsWith(prefix)),
    x => [...x]
  )(elt.attributes);

export const currentGroup = compose(
  when(Boolean, find(propEq("current", true))),
  prop("groups")
);
