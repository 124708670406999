import { bb } from "$json";
/* has-no-next:
 *
 * Set body.hasNoNext when the current screen title matches a
 * predefined regular expression, and unsets it when does not.
 *
 * Author: Niels Giesen
 *
 * Copyright (C) 2011, 2012 by Berkeley Bridge
 *
 */

(function ($) {
  // Regular expression to test absolute screentitles against:
  var endnodes = ["main.finish"];

  $(document).on("bb:postHandleData", function (event, data) {
    if (data && data.groups)
      bb.Mode.toggle(
        "hasNoNext",
        data.hasnext === false ||
          data.groups.some(function (group) {
            return group.current && endnodes.indexOf(group.name) > -1;
          })
      );
  });
})(jQuery);
